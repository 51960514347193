@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
  margin: 0;
  padding: 0;
}

body{
  overflow-x: hidden;
}

.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.title{
  width: 100%;
  height: 500px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./title.png);
}

.content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.text{
  margin-bottom: 86px;
  margin-right: 80px;
  color: white;
  align-items: flex-start;
}

.maintext{
  font-size: 48px;
  font-weight: 700;
}

.subtext{
  margin-top: 15px;
  font-weight: 400;
  font-size: 20px;
}

.blue{
  margin-left: 47px;
  width: 360px;
  height: 500px;
  background-color: #3A1BB5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.language{
  margin-top: 46px;
  display: flex;
  flex-direction: row;
}

.RU{
  font-family: 'Roboto';
  padding: 6px;
  font-weight: 700;
  font-size: 28px;
  color: #FEB119;
  border: 2px solid #FEB119;
  border-radius: 6px;
}

.KZ{
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  margin-left: 10px;
  color: white;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
}

.KZ:hover{
  color: #FEB119;
}

.phone{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 94px;
  border: 5px solid #FEB119;
  border-radius: 20px;
  align-items: center;
  color: #FEB119;
}

.phoneicon{
  margin-left: 35px;
}

.number{
  margin-left: 20px;
  margin-right: 25px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 80px;
}

.phone:hover{
  border-color: white;
  color: white;
}

.call{
  line-height: 48px;
  margin-top: 10px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 28px;
  color: white;
}


.whatsapp{
  margin-top: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.number1{
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 36px;
  margin-left: 21.17px;

  color: #FFFFFF;
}

.number1:hover{
  color:#FEB119;
}

.mainpart{
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.catalog{
  margin-top: 60px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sideinfo{
  margin-top: 60px;
  width: 360px;
  height: 1370px;
  
  background: rgba(255, 138, 1, 0.05);
}

.row1{
  display: flex;
  flex-direction: row;
}

.item1{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: 430px;
  background: #F8F8F8;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-right: 40px;
}

.item2{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: 430px;
  background: #F8F8F8;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-right: 40px;
}

.item1icon{
  margin-top: 22px;
  margin-bottom: 22px;
}

.item2icon{
  margin-top: 20px;
  margin-bottom: 30px;
}

.item1title{
  font-family: 'Roboto';
  font-weight: 500; 
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 15px;
}

.bar{
  width: 300px;
  height: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.item1options{
  width: 300px;
  margin-top: 33px;
}

.item1option1{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 54px;

  background: #66D144;
  border-radius: 6px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;

  color: #FFFFFF;
}

.row2{
  display: flex;
  margin-top: 40px;
}

.row3{
  display: flex;
  margin-top: 40px;
}

.button:hover{
  background-color: #53B135;
  ;
}

.barmain{
  margin-top: 60px;
  margin-bottom: 20px;

  width: 1160px;
  height: 0px;

  border: 1px solid rgba(0, 0, 0, 0.15);
}

.barmain1{
  margin-top: 20px;
  margin-bottom: 10px;

  width: 1160px;
  height: 0px;

  border: 1px solid rgba(0, 0, 0, 0.15);
}

.lasttext{
  line-height: 32px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;

  color: #000000;
}

.copyright{
line-height: 24px;
font-family: 'Roboto';
font-weight: 400;
font-size: 14px;
margin-bottom: 40px;

color: #000000;
}

.info{
  display: flex;
  justify-content: center;
  margin-top: 29px;
  margin-left: 25px;
  margin-right: 25px;
}

.num{
  margin-right: 10px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 22px;
}

.textinfo{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
}

.sideinfo{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.barinfo{
  width: 310px;
  height: 0px;
  margin-top: 20px;
  margin-bottom: 20px;

  border: 1px solid #FF8A01;
}

.info1{
  display: flex;
  justify-content: center;
  margin-left: 25px;
  margin-right: 25px;
  line-height: 36px;
}

@media only screen and (max-width: 800px) {
  .title{
    width: 100vw;
    height: 687px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./title.png);
    background-position: center;
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .maintext{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .subtext{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .text{
    margin-left: 40px;
    margin-bottom: 0px;
    margin-top: 190px;
  }
  .blue{
    height: 259px;
    margin-top: 20px;
    margin-left: 0;
    width: 100vw;
  }
  .language{
    margin-top: 0;
    position: absolute;
    right: 0%;
    top: 0%;
    width: 110px;
    height: 56px;
    
    background: #3A1BB5;    
  }
  .RU{
    display: flex;
    justify-content: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    padding: 4px;
    margin-top: 10px;
    margin-left: 16px;
    margin-right: 6px;
    margin-bottom: 10px;

  }
  .KZ{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }
  .phone{
    width: 265px;
    height: 105px;
    margin-top: 30px;
    align-items: center;
  }

  .number{
    font-size: 64px;
    line-height: 75px;
  }
  .phoneicon{
    width: 28px;
    height: 47px;
    padding-bottom: 13px
  }
  .call{
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }
  .whatsapp{
    margin-top: 15px;
  }
  .icon{
    width: 34.83px;
    height: 35px;
  }
  .number1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
  }
  .mainpart{
    flex-direction: column-reverse;
    align-items: center;
  }
  .sideinfo{  
    margin-top: 40px;
    width: 335px;
    height: 948px;
  }
  .num{
    width: 15px;
    height: 28px;
  }
  .textinfo{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  .barinfo{
    width: 295px;
  }

  .item1 img {
    width: 73px;
    height: 18px;
  }

  .item2 img {
    height: 20px;
  }

  .item1{
    width: 158px;
    height: 220px;
    margin-right: 20px;
  }

  .item1icon{
    width: 73px;
    height: 18px;
    margin-top: 14px;
    margin-bottom: 13px;
  }

  .item2icon{
    margin-top: 11px;
    margin-bottom: 9px;
  }
  

  .item1title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .bar{
    width: 138px;
  }

  .item1options {
    width: 138px;
    margin-top: 15px;
  }
  .item1option1 {
    margin-bottom: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
  }
  .button{
    width: 138px;
    height: 30px;
    margin-top: 7px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
  }
  .item2{
    width: 158px;
    height: 220px;
    margin-right: 0px;
  }
  .barmain{
    margin-top: 40px;
    margin-bottom: 10px;

    width: 335px;
  }
  .lasttext{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .barmain1{
    margin-top: 20px;
    margin-bottom: 10px;

    width: 335px;
  }
}